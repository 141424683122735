import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalMobileDevice.module.scss";

export const ModalMobileDevice = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.MOBILE_DEVICE));
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.MOBILE_DEVICE, value: false}))
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.MOBILE_DEVICE} zIndex={1005} >
      <div className={s.modalMessage}>
        <p className={s.title}>We noticed you are using a mobile device. For optimal performance a desktop computer or laptop is recommended.</p>
        <div className={s.btnsWrap}>
          <div className={s.col}>
            <ButtonMain text="Continue" onClick={handleContinue} color="blueDark" size="bigSuper" fullWidth={true} bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}