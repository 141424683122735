import { ModalDecorativeEnd } from "../ModalDecorativeEnd/ModalDecorativeEnd"
// import { ModalFramedType } from "../ModalFramedType/ModalFramedType"
import { ModalHelp2D } from "../ModalHelp2D/ModalHelp2D"
import { ModalPrice } from "../ModalPrice/ModalPrice"
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks"
import { getModalInfo } from "../../../store/selectors/modalsSelectors"
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT"
import { ModalHelp3D } from "../ModalHelp3D/ModalHelp3D"
import { ModalContinue2D } from "../ModalContinue2D/ModalContinue2D"
import { ModalSendEmailSuccess } from "../ModalSendEmailSuccess/ModalSendEmailSuccess"
import { ModalSendEmailError } from "../ModalSendEmailError/ModalSendEmailError"
import { Loader } from "../Loader/Loader"
import { ModalRemoveAllCabinets } from "../ModalRemoveAllCabinets/ModalRemoveAllCabinets"
import { ModalMessage } from "../ModalMessage/ModalMessage"
import { ModalMessageToeKick } from "../ModalMessageToeKick/ModalMessageToeKick"
import useMobileWidth from "../../../hooks/useMobileWidth"
import { useEffect } from "react"
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice"
import { ModalMobileDevice } from "../ModalMobileDevice/ModalMobileDevice"

export const ModalsGlobal = () => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.PRICE));
  const isMobSize = useMobileWidth(991);

  useEffect(() => {
    if (isMobSize) {
      dispatch(setModalVisible({
        modalId: MODAL_KEYS.MOBILE_DEVICE,
        value: true,
        isFirstShow: true,
      }))
    }
  }, [isMobSize]);
  return (
    <>
      <ModalDecorativeEnd />
      {/* <ModalFramedType /> */}
      <ModalHelp2D />
      <ModalHelp3D />
      <ModalContinue2D />
      {modalInfo["isVisible"] && <ModalPrice />}
      <ModalSendEmailSuccess />
      <ModalSendEmailError />
      <ModalRemoveAllCabinets />
      <ModalMessage />
      <ModalMessageToeKick />
      <ModalMobileDevice />
      <Loader />
    </>
  )
}